import Experience from './Experience.js'

export default class Mouse {
    constructor() {
        this.experience = new Experience()
        this.time = this.experience.time
        this.camera = this.experience.camera
        this.world = this.experience.world
        this.sizes = this.experience.sizes

        this.setMouse()
    }

    setMouse() {
        this.mouse = {}
        this.mouse.x = 0
        this.mouse.y = 0

        window.addEventListener('mousemove', (event) => {
            this.mouse.x = event.clientX / this.sizes.width - 0.5
            this.mouse.y = event.clientY / this.sizes.height - 0.5
        })
    }

    update() {
        const parallaxX = this.mouse.x
        const parallaxY = - this.mouse.y
        this.camera.instance.position.x = parallaxX * 0.75
        this.camera.instance.position.y = parallaxY * 0.75
    }
}