let sectionEndeavourStoriesObserver = Array.from(document.querySelectorAll('.section-story'));
const changeStoryImageObserver = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            Array.from(entry.target.querySelectorAll('.block-culture-story__image')).forEach((image) => {
                image.classList.add('scaleToIframeSize');

                // TODO Refactor this to keyframe animation
                image.addEventListener('webkitTransitionEnd', function() {
                    image.style.backgroundImage = 'none';
                }, false );
            });
        } else {
            Array.from(entry.target.querySelectorAll('.block-culture-story__image')).forEach((image) => {
                image.classList.remove('scaleToIframeSize');

                // TODO Refactor this to keyframe animation
                image.addEventListener('webkitTransitionEnd', function() {
                    image.style.backgroundImage = `url("${image.dataset.backgroundImage}")`;
                }, false );
            });
        }
    });
};

const observer = new IntersectionObserver(changeStoryImageObserver, {
    root: null,
    threshold: .45,
    rootMargin: '200px 0px 200px 0px',
});

sectionEndeavourStoriesObserver.forEach((section) => {
    observer.observe(section);
});

function resizeIframe(obj) {
    obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
}
