import * as THREE from 'three'
import Experience from './Experience.js'

export default class Background {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene

        this.setGeometry()
        this.setMaterial()
        this.setMesh()
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(20, 20, 1, 1)
    }

    setMaterial() {
        this.material = new THREE.MeshStandardMaterial({
            color: 0x1e1e1e,
        })
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.z = -3.5
        this.scene.add(this.mesh)
    }
}