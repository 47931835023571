import * as THREE from 'three'
import Experience from './Experience.js'
import vertexShader from './Shaders/Particles/vertex.glsl'
import fragmentShader from './Shaders/Particles/fragment.glsl'
import { BufferGeometry } from 'three'

export default class Particles {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.mouse = this.experience.mouse

        this.count = 1000

        this.setGeometry()
        this.setMaterial()
        this.setPoints()
    }

    setGeometry() {
        this.geometry = new BufferGeometry()

        const positionArray = new Float32Array(this.count * 3)
        const progressArray = new Float32Array(this.count)
        const sizeArray = new Float32Array(this.count)
        const alphaArray = new Float32Array(this.count)

        for (let i = 0; i < this.count; i++) {
            positionArray[i * 3 + 0] = (Math.random() - 0.5) * 20
            positionArray[i * 3 + 1] = 0
            positionArray[i * 3 + 2] = (Math.random() - 0.5) * 10

            progressArray[i] = Math.random()
            sizeArray[i] = Math.random()
            alphaArray[i] = Math.random()
        }

        this.geometry.setAttribute('position', new THREE.Float32BufferAttribute(positionArray, 3))
        this.geometry.setAttribute('aProgress', new THREE.Float32BufferAttribute(progressArray, 1))
        this.geometry.setAttribute('aSize', new THREE.Float32BufferAttribute(sizeArray, 1))
        this.geometry.setAttribute('aAlpha', new THREE.Float32BufferAttribute(alphaArray, 1))
    }

    setMaterial() {
        this.material = new THREE.ShaderMaterial({
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthTest: false,
            uniforms: {
                uTime: { value: 0 },
            },
            vertexShader: vertexShader,
            fragmentShader: fragmentShader,
        })
    }

    setPoints() {
        this.points = new THREE.Points(this.geometry, this.material)
        this.points.position.y = - 5
        this.scene.add(this.points)
    }

    update() {
        this.material.uniforms.uTime.value = this.time.elapsed
    }

}