export default [
    {
        name: 'squareTexture',
        type: 'texture',
        path:
            [
                '../../images/square.png',
            ]
    },
    {
        name: 'circleTexture',
        type: 'texture',
        path:
            [
                '../../images/circle.png'
            ]
    },
    {
        name: 'dashTexture',
        type: 'texture',
        path:
            [
                '../../images/dash.png'
            ]
    },
    {
        name: 'triangleTexture',
        type: 'texture',
        path:
            [
                '../../images/triangle.png'
            ]
    },
]
