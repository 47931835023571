import * as THREE from 'three'
import Sizes from './Utils/Sizes.js'
import Time from './Utils/Time.js'
import Camera from './Camera.js'
import Renderer from './Renderer.js'
import World from './World.js'
import Resources from './Utils/Resources.js'
import assets from './assets.js'
import Mouse from './Mouse.js'

let instance = null

export default class Experience {
    constructor(canvas) {
        if (instance) {
            return instance
        }

        instance = this

        // Global access
        window.experience = this

        // Options
        this.canvas = canvas

        // Sizes
        this.sizes = new Sizes()

        // Time
        this.time = new Time()

        // Scene
        this.scene = new THREE.Scene()

        // Resources
        this.resources = new Resources(assets)

        // Camera
        this.camera = new Camera()

        // Renderer
        this.renderer = new Renderer()

        // World
        this.world = new World()

        // Mouse
        this.mouse = new Mouse()

        // Resize event
        this.sizes.on('resize', () => {
            this.resize()
        })

        // Time event
        this.time.on('tick', () => {
            this.update()
        })
    }

    resize() {
        this.camera.resize()
        this.renderer.resize()
    }

    update() {
        if (this.camera)
            this.camera.update()

        if (this.renderer)
            this.renderer.update()

        if (this.world)
            this.world.update()

        if (this.mouse)
            this.mouse.update()
    }
}

