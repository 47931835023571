import * as THREE from 'three'
import Experience from './Experience.js'

export default class Environment {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.sizes = this.experience.sizes
        this.camera = this.experience.camera
        this.mouse = this.experience.mouse

        this.setRectLightOne()
        this.setRectLightTwo()
        this.setAnimation()
    }

    setRectLightOne() {
        this.rectLightOne = new THREE.RectAreaLight('#0B0753', 30, 6, 1)
        this.rectLightOne.position.z = -2
        this.rectLightOne.position.x = -4
        this.scene.add(this.rectLightOne)
    }

    setRectLightTwo() {
        this.rectLightTwo = new THREE.RectAreaLight('#0B0753', 30, 6, 1)
        this.rectLightTwo.position.x = 4
        this.rectLightTwo.position.z = -2
        this.scene.add(this.rectLightTwo)
    }

    setAnimation() {
        window.addEventListener('scroll', () => {
            this.mouse.scroll = window.scrollY
            this.rectLightOne.intensity = 30 - (this.mouse.scroll / this.sizes.height) * 40
        this.rectLightTwo.intensity = 30 - (this.mouse.scroll / this.sizes.height) * 40
        })
    }

}