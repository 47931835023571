import Swiper, {Pagination, Navigation, EffectFade, Autoplay} from 'swiper';
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

let scrollDownToSliderBtn = document.querySelector('#scrollDownToSlider');
if (scrollDownToSliderBtn) {
    scrollDownToSliderBtn.addEventListener('click', function () {
        document.querySelector('#homeSwiper').scrollIntoView({behavior: 'smooth', block: 'center'});
    });
}

Swiper.use([Pagination, Navigation, EffectFade, Autoplay]);

// Pillars with animation swiper
let swiperOptions = {
    fadeEffect: {crossFade: true},
    effect: "fade",
    pagination: {
        el: '.swiper-pagination',
        type: 'progressbar',
    },
    allowTouchMove: true,
    slidersPerView: 1,
}

let swiper = new Swiper('.homeSwiper', swiperOptions);
let customSwiperPagination = document.querySelector('.custom-swiper-pagination');
let customPaginationButtonElements = document.querySelectorAll('.custom-pagination-button');
let customImages = document.querySelectorAll('.animation-images-img');

customPaginationButtonElements.forEach((element, index) => {
    element.addEventListener('click', () => swiper.slideTo(index));
});

swiper.on('activeIndexChange', (e) => {
    const index = e.activeIndex;

    const activeButtonElement = Array.from(customPaginationButtonElements)
        .find((element) => element.matches('.active'));

    if (activeButtonElement) {
        activeButtonElement.classList.remove('active');
    }
    customPaginationButtonElements.item(index).classList.add('active');

    const customPaginationButtonElementsWidth = customPaginationButtonElements.item(index).clientWidth;
    customSwiperPagination.style.setProperty(
        '--transform-pagination',
        `translateX(-${index * customPaginationButtonElementsWidth}px)`);

    const activeImageElement = Array.from(customImages)
        .find((element2) => element2.matches('.active'));
    if (activeImageElement) {
        activeImageElement.classList.remove('active');
    }
    customImages.item(index).classList.add('active');
});

// Partners logo swiper
let swiperOptionsPartners = {
    spaceBetween: 30,
    slidesPerView: 5,
    loop: true,
    autoplay: true,
    breakpoints: {
        980: {
            slidesPerView: 8,
            spaceBetween: 60,
        },
    }
}
new Swiper('.partners-slider', swiperOptionsPartners);

// This is only for the homepage
const header = document.querySelector('.navigation-header');
const mainContainer = document.querySelector('.data-container');

if (header) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(header, {
        scrollTrigger: {
            trigger: mainContainer,
            start: "top -150px",
            end: "bottom -1200px",
            toggleClass: {targets: header, className: "scrolled-navigation"},
        }
    });
}
