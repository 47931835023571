import Experience from './Experience.js'
import Particles from './Particles.js'
import Environment from './Environment.js'
import Background from './Background.js'
import Growth from './Growth.js'
import Platforms from './Platforms.js'
import Heroes from './Heroes.js'
import Content from './Content.js'

export default class World {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.background = new Background()

        this.resources.on('ready', () => {
            this.environment = new Environment()
            this.particles = new Particles()
            this.growth = new Growth()
            this.platforms = new Platforms()
            this.heroes = new Heroes()
            this.content = new Content()
        })
    }

    update() {
        if (this.particles)
            this.particles.update()

        if (this.platforms)
            this.platforms.update()

        if (this.heroes)
            this.heroes.update()

        if (this.growth)
            this.growth.update()

        if (this.content)
            this.content.update()
    }
}
