import Swiper, { EffectCreative } from 'swiper';

Swiper.use([EffectCreative]);

let swiperOptions = {
    loop: true,
    loopedSlides: 10,
    effect: 'creative',
    creativeEffect: {
        limitProgress: 2,
        prev: {
            // will set `translateZ(-400px)` on previous slides
            translate: [0, 0, -200],
            scale: .75,
        },
        next: {
            // will set `translateX(100%)` on next slides
            translate: ['50%', 0, 0, 0],
            rotate: [0, 0, 5],
            scale: .75,
        },
    },
}

let swiper = new Swiper(".culture-people-swiper", swiperOptions);
