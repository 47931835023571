import * as THREE from 'three'
import Experience from './Experience.js'

export default class Growth {
    constructor() {
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.time = this.experience.time
        this.sizes = this.experience.sizes
        this.mouse = this.experience.mouse
        this.resources = this.experience.resources
        this.world = this.experience.world

        this.setGeometry()
        this.setTextures()
        this.setMaterial()
        this.setMesh()
        this.setAnimation()
    }

    setGeometry() {
        this.geometry = new THREE.PlaneGeometry(0.6, 0.6, 1, 1)
    }

    setTextures() {
        this.textures = {}
        this.textures.color = this.resources.items.dashTexture
        this.textures.encoding = THREE.sRGBEncoding
    }

    setMaterial() {
        this.material = new THREE.MeshBasicMaterial({
            depthWrite: false,
            transparent: true,
            map: this.textures.color
        })
    }

    setMesh() {
        this.mesh = new THREE.Mesh(this.geometry, this.material)
        this.mesh.position.x = 0.2
        this.mesh.position.y = 0.4
        this.mesh.rotation.z = -0.5
        this.scene.add(this.mesh)
    }

    setAnimation() {
        window.addEventListener('scroll', () => {
            this.mouse.scroll = window.scrollY
            this.mesh.position.z = -0.5 -this.mouse.scroll / this.sizes.height * 4
            this.mesh.material.opacity = 1 - this.mouse.scroll / this.sizes.height * 2.5
        })
    }


    update() {
        this.mesh.position.y = 0.4 + Math.sin(this.time.elapsed * 0.001) * 0.01
        this.mesh.rotation.y = Math.sin(this.time.elapsed * 0.001) * 0.2
    }

}
