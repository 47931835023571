import LocomotiveScroll from 'locomotive-scroll';

window.onload = function() {
    // Hack: This is needed for smoothscroll bug: incorrect height.
    // Read more about it in the following issue
    // https://github.com/locomotivemtl/locomotive-scroll/issues/358
    window.dispatchEvent(new Event('resize'));
}

const handleMobileStickiness = (locomotiveContainer, scroll) => {
    let isSticky = false;
    if (locomotiveContainer && scroll) {
        scroll.on('scroll', (args) => {
            let scrollTop = args.scroll.y;
            if (scrollTop >= 122) {
                if (!isSticky) {
                    isSticky = true;
                    document.body.classList.add('sticky');
                }
            } else {
                if (isSticky) {
                    isSticky = false;
                    document.body.classList.remove('sticky');
                }
            }
        })
    } else {
        window.addEventListener('scroll', () => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (scrollTop >= 122) {
                if (!isSticky) {
                    isSticky = true;
                    document.body.classList.add('sticky');
                }
            } else {
                if (isSticky) {
                    isSticky = false;
                    document.body.classList.remove('sticky');
                }
            }
        })
    }
}

const handleLazyLoad = (iframe) => {
    if (iframe.classList.contains('lazyload')) {
        const storeSRC = iframe.dataset.src;
        iframe.addEventListener('lazyloaded', () => {
            delete iframe.dataset.src;
            iframe.src = storeSRC;
            initPlayer(iframe);
        });
    }
}

const initPlayer = (iframe) => {
    const player = new Vimeo.Player(iframe);
    player.ready().then(function () {
        // console.log('player is ready!');
        //
        // // These events are not attaching? Why?
        // player.on('play', function () {
        //     console.log('played the video!');
        // });
        //
        // player.on('ended', function () {
        //     console.log('the video has ended');
        // });
    });
}

const initLocomotive = () => {
    const locomotiveContainer = document.getElementById('locomotive')
    if (locomotiveContainer) {
        window.scroll = new LocomotiveScroll({
            el: document.querySelector('#locomotive'),
            smooth: true,
            repeat: true,
            getDirection: true
        });
    }
}

document.addEventListener('DOMContentLoaded', (event) => {
    initLocomotive();

    /* lazyload video embed iframe */
    const iframe = document.querySelector('iframe');
    if(iframe) {
        handleLazyLoad(iframe);
    }
    /* menu-desktop */
    const hamb = document.getElementById('hamb');
    const topBar = document.getElementById('navigation-top-bar');
    const subBar = document.getElementById('navigation-sub-bar');
    const desktopMenu = document.getElementById('menu-desktop-foldout');
    const mobileMenu = document.getElementById('menu-mobile');
    const dmmLinks = Array.from(document.getElementsByClassName('dmm-link'));
    const ddmFoldOut = document.getElementById('menu-desktop-foldout');
    const subLinks = Array.from(document.getElementsByClassName('desktop-sublinks'));
    const moreInfoBlocks = Array.from(document.getElementsByClassName('subservice-moreinfo'));
    const subIpadMenu = document.getElementById('navigation-sub-bar')
    const serviceToggle = document.getElementById('services-toggle-link')
    const serviceChevron = document.getElementById('service-chevron')

    let oldDropDown = '';
    let currentActiveLink = '';

    console.log(serviceToggle)

    serviceToggle.addEventListener('click', function() {
        subIpadMenu.classList.toggle('open')
        desktopMenu.classList.toggle('subMenuOpen')
        serviceChevron.classList.toggle('open')
    })

    window.addEventListener('click', function(e){
        if (
            !hamb.contains(e.target) &&
            !topBar.contains(e.target) &&
            !subBar.contains(e.target) &&
            !desktopMenu.contains(e.target) &&
            !mobileMenu.contains(e.target)
        ){
            // alert("Clicked outside menu-desktop");
            if (currentActiveLink) {
                document.getElementById(currentActiveLink).classList.remove('active');
                ddmFoldOut.classList.remove('active');
            }
        }
    });

    subLinks.forEach( link => {
        link.addEventListener('mouseover', event => {
            moreInfoBlocks.forEach( block => {
                const blockId = block.id.split('-')[1];
                const linkId = link.id.split('-')[0];
                // console.log('blockId', blockId, 'linkId', linkId);
                if( blockId === linkId ) {
                    block.classList.remove('active');
                }
            });
            document.getElementById(`moreinfo-${link.id}`).classList.add('active');
        });
    });

    dmmLinks.forEach(link => {
        link.addEventListener('click', event => {
            dmmLinks.forEach(link => {
                link.classList.remove('active')
            })
            let li = event.target;
            if (li.id === currentActiveLink) {
                ddmFoldOut.classList.remove('active');
                li.classList.remove('active');
                oldDropDown.classList.remove('active');
                currentActiveLink = '';
                return;
            }
            ddmFoldOut.classList.add('active');
            li.classList.add('active');
            if (currentActiveLink) {
                document.getElementById(currentActiveLink).classList.remove('active');
            }
            if (oldDropDown) {
                oldDropDown.classList.remove('active');
            }
            const newDropDown = document.getElementById(`menu-desktop--${li.id}`);
            newDropDown.classList.add('active');
            oldDropDown = newDropDown;
            currentActiveLink = li.id;
        });
    });

    /* menu-mobile main */
    const drop = document.querySelector('.menu-mobile');
    const backToMain = document.getElementById('back-to-main');
    const backToMainService = document.getElementById('back-to-main-service');
    const subMenu = document.getElementById('mobile-sub-menu');
    const subServiceMenu = document.getElementById('mobile-sub-menu-service');
    const dmmLinks2 = Array.from(document.getElementsByClassName('dmm-link2'));
    const dmmLinks3 = Array.from(document.getElementsByClassName('dmm-link3'));

    hamb.addEventListener('click', () => {
        // document.body.classList.toggle('no-scroll');
        hamb.classList.toggle('active');
        drop.classList.toggle('active');
        subMenu.classList.remove('active');
    });

    backToMain.addEventListener('click', () => {
        subMenu.classList.toggle('active');
    });

    backToMainService.addEventListener('click', () => {
        subServiceMenu.classList.toggle('active');
    });

    dmmLinks2.forEach(link => {

        link.addEventListener('click', event => {
            let li = event.target;
            subMenu.classList.add('active');
            if (currentActiveLink) {
                document.getElementById(`mobile-submenu--${currentActiveLink}`).classList.remove('active');
            }
            currentActiveLink = li.id;
            document.getElementById(`mobile-submenu--${currentActiveLink}`).classList.add('active');
        });
    });

    dmmLinks3.forEach(link => {

        link.addEventListener('click', event => {
            let li = event.target;
            subServiceMenu.classList.add('active');
            if (currentActiveLink) {
                document.getElementById(`mobile-submenu--${currentActiveLink}`).classList.remove('active');
            }
            currentActiveLink = li.id;
            document.getElementById(`mobile-submenu--${currentActiveLink}`).classList.add('active');
        });
    });

    if (document.getElementById('locomotive')) {
        const scroller = new LocomotiveScroll({
            el: document.querySelector('[data-scroll-container]'),
            smooth: true,
            resetNativeScroll: true,
            getSpeed: true,
            getDirection: true,
        });
    }

    if (document.getElementById('webgl')) {
        const webgl = document.getElementById('webgl');
        const style = webgl.currentStyle || window.getComputedStyle(webgl, false);
        const bi = style.backgroundImage.slice(4, -1).replace(/['"]/g, "");
        const bgimg = new Image();
        bgimg.src = bi;
        bgimg.onload = function () {
            webgl.classList.add('show');
        }
    }

    if (document.querySelectorAll(`[data-js='mouseIcon']`)) {
        let mousex = 0,
            mousey = 0,
            mouseIcons = document.querySelectorAll(`[data-js='mouseIcon']`);

        let vw = window.innerWidth;
        let vh = window.innerHeight;
        let vw2 = vw / 2;
        let vh2 = vh / 2;
        let mouseMoved = false;

        document.addEventListener('mousemove', (event) => {
            if (!mouseMoved) delayMouseFollow();
            mouseMoved = true;
            mousex = event.clientX;
            mousey = event.clientY;
        });

        let delay = 12,
            posx = 0,
            posy = 0,
            locx = 0,
            locy = 0;

        function delayMouseFollow() {
            requestAnimationFrame(delayMouseFollow);
            posx = (mousex > vw2) ? ((mousex - vw2) / vw2) : ((vw2 - mousex) / vw2);
            posy = (mousey > vh2) ? ((mousey - vh2) / vh2) : ((vh2 - mousey) / vh2);
            locx = (mousex > vw2) ? (vw2 + posx * vw2) : (vw2 - posx * vw2);
            locy = (mousey > vh2) ? (vh2 + posy * vh2) : (vh2 - posy * vh2);
            mouseIcons.forEach((mouseIcon) => {
                mouseIcon.style.top = locy + 'px';
                mouseIcon.style.left = locx + 'px';
            })
        }
    }

    // Infinite Ticker code

    var infiniteScrollDuration = 50000;
    var containers = document.querySelectorAll(".infinite-container")

    class InfiniteScroll{
        constructor(container, duration) {
            this.container = container;
            this.wrapper = this.container.querySelectorAll('.infinite-wrapper')[0];
            // set the duration based on 60fps
            this.duration = 60 * (duration / 1000);
            this.increment = 0;
            this.getWidth = this.getWidth.bind(this);
            this.startScroll = this.startScroll.bind(this);
            this.getWidth();
            window.addEventListener('resize',this.getWidth);
            this.startScroll();
        }

        // get container width
        getWidth(){
            this.start = false;
            this.block = this.wrapper.querySelectorAll('.items-block');
            this.step = Math.floor(this.block[0].clientWidth);
            this.width = Math.ceil(this.container.clientWidth / this.block[0].clientWidth) + 1;
            this.setBlocks();
        }

        // dynamic blocks duplicator
        setBlocks(){
            if(this.block.length < this.width){
                for(var i =0; i < (this.width - this.block.length); i++){
                    this.cloneBlock();
                }
            }else{
                for(var i =0; i < (this.block.length - this.width); i++){
                    this.removeBlock();
                }
            }
            this.start = true;
        }

        // remove blocks
        removeBlock(){
            this.wrapper.querySelectorAll('.items-block')[0].remove();
        }

        // add blocks
        cloneBlock(){
            var clone = this.block[0].cloneNode(true);
            this.wrapper.appendChild(clone);
        }

        // Scrolling
        startScroll(){
            this.increment += this.step / this.duration;

            if(parseInt(this.increment) >= this.step){
                this.removeBlock();
                this.cloneBlock();
                this.increment = 0;
                this.wrapper.style.transform = 'translateX(0px)';
            }
            else{
                this.wrapper.style.transform = 'translateX('+ -parseInt(this.increment) +'px)';
            }
            if(this.start){
                window.requestAnimationFrame(this.startScroll);
            }
            else{
                window.cancelAnimationFrame(this.startScroll);
            }
        }
    }

    // init scrolls for containers
    for (var i = 0; i < containers.length; ++i) {
        new InfiniteScroll(containers[i], infiniteScrollDuration);
    }
});


