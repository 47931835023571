import Experience from './Experience/Experience.js'
// ------------------------------------------------------------------------------
//      Global imports
// ------------------------------------------------------------------------------
require('./global/old-global');

// ------------------------------------------------------------------------------
//      Components imports
// ------------------------------------------------------------------------------
require('./components/navigation');

// ------------------------------------------------------------------------------
//      Pages imports
// ------------------------------------------------------------------------------
document.addEventListener('DOMContentLoaded', () => {
    require('./pages/culture/culture-scrolling');
    require('./pages/culture/culture-avatar-swiper');
    require('./pages/culture/culture-stories');
    require('./pages/home/home');

    new Experience(document.querySelector('canvas.webgl'));
});
