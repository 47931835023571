import LocomotiveScroll from "locomotive-scroll";
import ScrollTrigger from "gsap/ScrollTrigger";
import gsap from "gsap";

let scrollContainer = document.querySelector(".smooth-scroll-container");

if (scrollContainer) {
    gsap.registerPlugin(ScrollTrigger);

    const locoScrollCulture = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true
    });

    locoScrollCulture.on("scroll", ScrollTrigger.update);

    ScrollTrigger.scrollerProxy(".smooth-scroll-container", {
        scrollTop(value) {
            return arguments.length ? locoScrollCulture.scrollTo(value, 0, 0) : locoScrollCulture.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
            return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight};
        },
        pinType: document.querySelector(".smooth-scroll-container").style.transform ? "transform" : "fixed"
    });

    const sections = gsap.utils.toArray('section')

    sections.forEach(function (section) {
        const inner = section.querySelector('.section-inner');
        if (!section.classList.contains('culture--horizontal-scrolling')) {
            ScrollTrigger.create({
                scroller: '.smooth-scroll-container',
                trigger: section,
                start: section.scrollHeight <= window.innerHeight ? 'top top' : 'bottom bottom',
                end: '+=100%',
                pin: inner,
                pinSpacing: false,
                pinType: 'transform'
            })
        } else {
            const scroll = section.querySelector('[data-scroll-in-section]');
            // the tween and the pinning have two different ScrollTriggers, because the will need different durations for that overlaying-effect to show
            ScrollTrigger.create({
                scroller: '.smooth-scroll-container',
                trigger: section,
                start: 'center center',
                end: () => `+=${section.scrollWidth + window.innerHeight}`, // added an extra window.innerHeight to the end here in comparison to the tween
                pin: inner,
                pinSpacing: true,
                pinType: 'transform',
                anticipatePin: 1,
            });

            gsap.to(scroll, {
                x: () => `${-(section.scrollWidth - document.documentElement.clientWidth)}px`,
                ease: 'none',
                scrollTrigger: {
                    trigger: scroll,
                    scroller: '.smooth-scroll-container',
                    start: 'center center',
                    end: () => `+=${section.scrollWidth}`,
                    scrub: true,
                }
            });
        }
    });

    ScrollTrigger.addEventListener("refresh", () => locoScrollCulture.update());
    ScrollTrigger.refresh();
}
