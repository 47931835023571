import * as THREE from 'three'
import { TextureLoader } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader.js'
import EventEmitter from './EventEmitter.js'

export default class Resources extends EventEmitter {
    constructor(assets) {
        super()

        this.assets = assets

        this.items = {}
        this.toLoad = this.assets.length
        this.loaded = 0

        this.setLoaders()
        this.startLoading()
    }

    setLoaders() {
        this.loaders = {}
        this.loaders.gltfLoader = new GLTFLoader()
        this.loaders.textureLoader = new THREE.TextureLoader()
    }

    startLoading() {
        for (const asset of this.assets) {
            if (asset.type === 'gltfModel') {
                this.loaders.gltfLoader.load(
                    asset.path,
                    (file) => {
                        this.assetLoaded(asset, file)
                    }
                )
            } else if (asset.type === 'texture') {
                this.loaders.textureLoader.load(
                    asset.path,
                    (file) => {
                        this.assetLoaded(asset, file)
                    }
                )
            }
        }
    }

    assetLoaded(asset, file) {
        this.items[asset.name] = file
        this.loaded++
        if (this.loaded === this.toLoad) {
            this.trigger('ready')
        }
    }
}